
const DefaultValuesDEV = {
    HOME: "https://dev.coincheaper.com/",
    CLIENT: "https://dev.coincheaper.com/client/",
    PAY: "https://devpay.coincheaper.com/",
    SECURE: "https://devsecure.coincheaper.com/"
};

const DefaultValuesProd = {
    HOME: "https://coincheaper.com/",
    CLIENT: "https://coincheaper.com/client/",
    PAY: "https://pay.coincheaper.com/",
    SECURE: "https://secure.coincheaper.com/"
};

export var DefaultValues = DefaultValuesProd;

export function initVars(is_dev: boolean) {
    if (is_dev) {
        DefaultValues = DefaultValuesDEV;
    }
}
