import * as React from 'react';

import {Component} from "react";
import {ApiData} from "../../models/ApiData";

export default class AvailableInCountry extends Component {
    state = {
        country: false,
        is_enabled: true
    }

    public componentDidMount(): void {
        ApiData.clientApiRequest({}, "get_country").then((data) => {
            console.log("COUN", data)
            if(data.success)
                this.setState({
                    country: data.data['name'],
                    is_enabled: data.data['is_enabled'] == 1
                });
        });
    };

    public render = () => {
        return this.state.country ?
            (this.state.is_enabled ?
                <div className="exchange__avialibe avialibe radius">
                    <span className="exchange__avialibe_text">CoinCheaper is available in {this.state.country}</span>
                </div>
                :
                <div className="exchange__avialibe no-avialibe radius exchange__avialibe_card">
                    <span className="exchange__avialibe_text">CoinCheaper is not available in {this.state.country}</span>
                </div>
            )
            : ''

    }
}
